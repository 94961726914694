var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.table_header)+" ")]),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":[{ key: 'section', label: this.column_name, sortable: true }].concat(
        _vm.fields
      ),"small":"","responsive":"","thead-class":"custom-header small","table-class":"simple-report-table small","bordered":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',{staticClass:"custom-table-header"},[(data.field.label == 'PERFIL DE EGRESO')?[_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil De Egreso" ).toUpperCase())+" ")])]:(
            data.field.label != 'N°' &&
            data.field.label != 'AÑO DE INGRESO' &&
            data.field.label != 'IDENTIFICADOR' &&
            data.field.label != 'APELLIDO' &&
            data.field.label != 'NOMBRE' &&
            data.field.label != 'PERFIL DE EGRESO'
          )?[_c('div',{attrs:{"id":`assessment-header-${data.field.label.split(',')[0]}`}},[_vm._v(" "+_vm._s(data.field.label.split(",")[0])+" ")]),_c('b-tooltip',{attrs:{"target":`assessment-header-${data.field.label.split(',')[0]}`,"variant":"secondary","placement":"top","custom-class":"custom-tooltip"}},[_vm._v(_vm._s(data.field.label.split(",")[1]))])]:[_c('div',[_vm._v(" "+_vm._s(data.field.label)+" ")])]],2)]}},{key:"cell()",fn:function(data){return [[(
            _vm.chartdata.extra_data &&
            data.field.label != 'N°' &&
            data.field.label != 'AÑO DE INGRESO' &&
            data.field.label != 'IDENTIFICADOR' &&
            data.field.label != 'APELLIDO' &&
            data.field.label != 'NOMBRE' &&
            data.field.label != 'PERFIL DE EGRESO'
          )?_c('div',{class:{
            'bg-red':
              data.value <=
              _vm.chartdata.extra_data.achievement_ranges[0][2] + '%',
            'bg-yellow':
              data.value >=
                _vm.chartdata.extra_data.achievement_ranges[1][1] + '%' &&
              data.value <=
                _vm.chartdata.extra_data.achievement_ranges[1][2] + '%',
            'bg-green':
              data.value >=
                _vm.chartdata.extra_data.achievement_ranges[2][1] + '%' ||
              data.value ==
                _vm.chartdata.extra_data.achievement_ranges[2][2] + '%',
            'bg-transparent': data.value == '-',
          }},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])]]}}])}),(!_vm.chartdata.datasets.length)?[_c('div',{staticClass:"empty-dataset-alert"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" No hay datos para mostrar. ")],1)]:_vm._e(),(_vm.chartdata.extra_data)?[_c('div',{staticClass:"report-ranges-caption"},[_c('div',{staticClass:"report-ranges-header"},[_vm._v("RANGOS DE LOGROS")]),_vm._l((_vm.chartdata.extra_data
          .achievement_ranges),function(grade_range,index){return [_c('div',{key:grade_range.id,staticClass:"report-range"},[_c('span',{staticClass:"range-label"},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][0])+" ")]),_c('div',{class:{
              'range-value-red': index == 0,
              'range-value-yellow': index == 1,
              'range-value-green': index == 2,
            }},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][1] + "%")+" - "+_vm._s(_vm.chartdata.extra_data.achievement_ranges[index][2] + "%")+" ")])])]})],2)]:_vm._e(),[_c('download-excel',{staticClass:"btn btn-primary mt-2",attrs:{"data":_vm.itemsXLS,"fields":_vm.jsonFields,"worksheet":`POR ${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      ).toUpperCase()} - UNIDADES`,"name":`Reporte Assessment (${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      )}-Unidades).xls`,"header":_vm.headerArray,"footer":_vm.footerArray}},[_vm._v(" Descargar XLS ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }